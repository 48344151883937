import React from 'react';
import { FaStar, FaThumbsUp, FaGlobe, FaRupeeSign,FaShippingFast } from 'react-icons/fa';

const Features = () => {
  return (
    <section className="features" id="features">
      <h2>
        Why<font color="tomato"> Choose Us</font>
      </h2>
      <p className="section-desc">
        <strong>We at JRP Soft Tech provide you with complete software
        development solutions.</strong>
      </p>
      <div className="row">
        <div className="column">
          <i className="fas fa-star"><FaThumbsUp className="icon" /></i>
          <h3>98% Client Satisfaction</h3>
        </div>
        <div className="column">
          <i className="fas fa-thumbs-up"><FaShippingFast className="icon" /></i>
          <h3>85% On-Time Project Delivery</h3>
        </div>
        <div className="column">
          <i className="fas fa-users"><FaGlobe className="icon" /></i>
          <h3>Global Reach with Clients in 15+ Countries</h3>
        </div>
        <div className="column">
          <i className="fas fa-bullhorn"><FaRupeeSign className="icon" /></i>
          <h3>Affordable Pricing with No Hidden Costs</h3>
        </div>
      </div>
    </section>
  );
};

export default Features;
