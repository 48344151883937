import React, { useState, useEffect } from 'react';
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";

const Header = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Your scroll logic here
      let pos = document.documentElement.scrollTop;
      // ... (rest of your scroll logic)
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive);
  };

  const closeMenu = () => {
    setIsMenuActive(false);
  };

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  return (
    <header>
      <nav>
        <a href="#home" id="logo">
          <span style={{ color: 'white' }}>APP</span>{' '}
          <span style={{ color: 'white' }}>SOLUTIONS</span> 
        </a>

        <i className={`fas fa-bars ${isMenuActive ? 'active' : ''}`} id="ham-menu" onClick={toggleMenu}>
          <GiHamburgerMenu/>
        </i>

        <ul id="nav-bar" className={isMenuActive ? 'active' : ''}>
          <li>
            <a href="#home" onClick={closeMenu}>Home</a>
          </li>
          
          <li>
            <a href="#about" onClick={closeMenu}>About</a>
          </li>
          
          <li>
            <a href="#services" onClick={closeMenu}>Services</a>
          </li>
          <li>
            <a href="#pricing" onClick={closeMenu}>Pricing</a>
          </li>

          {/*
          <li>
            <a href="#testimonial" onClick={closeMenu}>Testimonial</a>
          </li> 
          */}
          <li>
            <a href="#Contact-Us" onClick={closeMenu}>Contact-Us</a>
          </li>
        </ul>
      </nav>

      <button id="scroll-top" style={{ display: 'none' }} onClick={scrollToTop}>
        <i className="fas fa-arrow-up"></i>
      </button>
    </header>
  );
};

export default Header;
