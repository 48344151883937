import React from 'react';
import './faq.css'
const Faq = () => {
  return (
    <section class="faq" id="faq">
      <h2>Frequently Asked Questions</h2>
      <p class="section-desc">
        Have questions about our services ? We've got answers.
      </p>
      <div class="faq-container">
        
    <div class="faq">
      {/*
    <h1>Frequently Asked Questions</h1>
      */}
    <div class="faq-item">
        <input type="checkbox" id="faq1"/>
        <label for="faq1" class="faq-item-title"><span class="icon"></span>1. What types of mobile apps do you develop?</label>
        <div class="faq-item-desc">At App Solutions by JRP , We specialize in developing a wide range of mobile applications, including iOS, Android, and cross-platform apps.</div>
    </div>

    <div class="faq-item">
        <input type="checkbox" id="faq2"/>
        <label for="faq2" class="faq-item-title"><span class="icon"></span>2. How long does it take to develop a mobile app?</label>
        <div class="faq-item-desc">The development timeline varies based on the complexity and features of the app. We work closely with clients to provide accurate project timelines during the consultation phase.</div>
    </div>

    <div class="faq-item">
        <input type="checkbox" id="faq3"/>
        <label for="faq3" class="faq-item-title"><span class="icon"></span>3. Do you provide UI/UX design services for mobile apps?</label>
        <div class="faq-item-desc">Yes, we offer comprehensive UI/UX design services to ensure your app not only functions seamlessly but also provides an intuitive and engaging user experience.</div>
    </div>

    <div class="faq-item">
        <input type="checkbox" id="faq4"/>
        <label for="faq4" class="faq-item-title"><span class="icon"></span>4. Can you assist with app store submissions?</label>
        <div class="faq-item-desc">Absolutely! We guide clients through the entire app store submission process, ensuring compliance with guidelines and maximizing the chances of approval.</div>
    </div>

    <div class="faq-item">
        <input type="checkbox" id="faq5"/>
        <label for="faq5" class="faq-item-title"><span class="icon"></span>5. What technologies do you use for app development?</label>  
        <div class="faq-item-desc">
        We leverage a variety of technologies and frameworks, including but not limited to Swift, Kotlin, React Native, and Flutter, to deliver high-quality and versatile mobile applications.</div>
    </div>


    <div class="faq-item">
        <input type="checkbox" id="faq6"/>
        <label for="faq6" class="faq-item-title"><span class="icon"></span>6. Is ongoing maintenance and support provided after app deployment?</label>  
        <div class="faq-item-desc">
        Yes, we offer post-launch maintenance and support services to address any issues, implement updates, and ensure your app continues to perform optimally.</div>
    </div>

    <div class="faq-item">
        <input type="checkbox" id="faq7"/>
        <label for="faq7" class="faq-item-title"><span class="icon"></span>7. How  can a client involves in the app development process?</label>  
        <div class="faq-item-desc">
        We encourage client involvement throughout the development process. Regular updates, feedback sessions, and milestone reviews ensure the final product aligns with client expectations. </div>
    </div>



    <div class="faq-item">
        <input type="checkbox" id="faq8"/>
        <label for="faq8" class="faq-item-title"><span class="icon"></span>8. What security measures do you implement in app development?</label>  
        <div class="faq-item-desc">
        Security is a top priority. We implement industry best practices, conduct thorough testing, and stay updated on the latest security trends to safeguard user data and app functionality. </div>
    </div>


    <div class="faq-item">
        <input type="checkbox" id="faq9"/>
        <label for="faq9" class="faq-item-title"><span class="icon"></span>9. How is the cost of app development determined?</label>  
        <div class="faq-item-desc">
        The cost depends on various factors, including project complexity, features, and platform. We provide transparent pricing models and work closely with clients to align services with their budget.</div>
    </div>
    <div class="faq-item">
        <input type="checkbox" id="faq10"/>
        <label for="faq10" class="faq-item-title"><span class="icon"></span>10. Can you share examples of previous app development projects?</label>  
        <div class="faq-item-desc">
        Certainly! We have a portfolio showcasing a variety of successful app development projects. Feel free to explore our portfolio to get an idea of our expertise and the types of apps we've worked on.</div>
    </div>

</div>
      </div>
    </section>
  );
};

export default Faq;



