import React from "react";
import '../components/price1.css'
const Price1 = () => {
    return (
  
<section>
 
<h2 className="h22">Our pricing</h2>
<div id="pricing" class="container10">

{/*1 plan */}
  <div class="pricecard10">
    <div class="head10">
      <h4 class="h22">Basic</h4>
      <p class="sub">For the small companies</p>
      <p class="price10">&#8377; 50,000</p>
    </div>
    <div class="details10">
      <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p> Development for a single platform (iOS or Android)</p>
      </div>
      <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p>Standard UI/UX design</p>
      </div>
      <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p>Core app features</p>
      </div>
      <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p>App store submission assistance</p>
      </div>
      <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p>1-month post-launch support</p>
      </div>
      
    
    </div>
    <button1>Buy Now</button1>
  </div>
{/*2 plan*/}
  <div class="pricecard10">
    <div class="head10">
      <h4 class="h22">Plus</h4>
      <p class="sub">For the medium companies</p>
      <p class="price10">&#8377; 1,00,000</p>
    </div>
    <div class="details10">
    <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p> Cross-platform development (iOS and Android)</p>
      </div>
      <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p>Custom UI/UX design</p>
      </div>
      <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p>Advanced app features</p>
      </div>
      <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p>App store submission assistance</p>
      </div>
      <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p>2-month post-launch support</p>
      </div>
      
    </div>
    <button1>Upgrade Now</button1>
  </div>
{/*3 plan  */}
  <div class="pricecard10">
    <div class="head10">
      <h4 class="h22">Premium</h4>
      <p class="sub">For the big companies</p>
      <p class="price10">&#8377; 2,50,000</p>
    </div>
    <div class="details10">
      
    <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p> Cross-platform development (iOS and Android)</p>
      </div>
      <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p>Custom UI/UX design with prototyping</p>
      </div>
      <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p>Advanced app features, including push notifications and in-app purchases</p>
      </div>
      <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p>App store submission assistance with priority review</p>
      </div>
      <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p>3-month post-launch support</p>
      </div>
      
      <div class="row10">
        <svg viewBox="0 0 512 512" width="100" title="check">
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        </svg>
        <p>Basic marketing consultation</p>
      </div>
      </div>
    <button1>Upgrade Now</button1>
  </div>

</div>
</section>


);
};


export default Price1;
