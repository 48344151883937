import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Services from './components/Services';

import Testimonial from './components/Testimonial';
import Contact from './components/Contact';

import Footer from './components/Footer';
import Map from './components/map';
import About from './components/about';
import Faq from './components/faq';

import Price1 from './components/price1';



import ScrollToTopButton from './components/ScrollToTopButton';

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <About/>
      <Services />
      <Price1/>
      <Features />
      <Faq/>
      <Testimonial />
      <Contact />
      <Map/>
      <ScrollToTopButton/>
      <Footer />
          
    </div>
  );
}

export default App;