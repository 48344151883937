import React from 'react';
import one from '../img/hybrid.png';
import two from '../img/native.png';
import three from '../img/ecom.png';

const Services = () => {
  return (
    <section className="services" id="services">
      <h2>Services<font color="tomato"> We Offer</font></h2>
      <p className="section-desc">
         services.
      </p>

      <div className="row">
        <div className="column">
          <img src={one} alt="JRP Soft Tech" />
          <h3>Hybrid App Development</h3>
          <p>
            At App Solutions by JRP , Our Hybrid App Development service combines the best of both worlds – native and web applications. Using technologies like React Native or Flutter, we create cross-platform apps that can run on multiple operating systems with a single codebase.
          </p>
          <div className="services-btn">
            <a href="https://jrpsofttech.com/shop/webdevelopment.html">
              <button className="btn secondary-btn">Learn More</button>
            </a>
          </div>
        </div>
        
        <div className="column">
          <img src={two} alt="JRP Soft Tech" />
          <h3>Native App Development</h3>
          <p>
          Our Native App Development service focuses on building applications specifically for a single platform, either iOS or Android. We use platform-specific technologies like Swift or Kotlin to ensure optimal performance and user experience.
          </p>
          <div className="services-btn">
            <a href="https://jrpsofttech.com/shop/seo.html">
              <button className="btn secondary-btn">Learn More</button>
            </a>
          </div>
        
        </div>

        <div className="column">
          <img src={three} alt="JRP Soft Tech" />
          <h3>E-commerce App Development</h3>
          <p>
          Our E-commerce App Development service caters to businesses looking to establish or enhance their online presence. We create feature-rich, secure, and scalable e-commerce applications to boost sales and customer engagement.
          </p>
          <div className="services-btn">
            <a href="https://jrpsofttech.com/shop/logodesign.html">
              <button className="btn secondary-btn">Learn More</button>
            </a>
          </div>
        </div>
        </div>
      
    </section>
  );
};

export default Services;
