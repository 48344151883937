import React from 'react';

import './about.css';

const About = () => {
  return (
    <section className="about" id="about">
      <h2>About Us<font color="tomato"></font></h2>
      <h4 className="section-desc">
      Who We Are:
      </h4>
        <div className="hero-content">
        <p style={{ color: 'white', fontSize: '2rem' }}>
        At App Solutions by JRP, we're more than just a development company, we're your partners in turning ideas into powerful, user-friendly apps. Our team is driven by passion, creativity, and a commitment to excellence in native and hybrid app development.
        </p>
        </div>
    </section>


  );
};


export default About;

