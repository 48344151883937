import React from 'react';
import jrpimg from '../img/hero1.png';


const Hero = () => {
  return (
    <section className="hero" id="home">
      <img src=".svg" id="header-shape" alt="header jrp soft tech" />
      <div className="hero-content">
        <h1 style={{ color: 'white', fontFamily: 'Gill Sans, sans-serif' }}>
        Welcome to App Solutions by JRP
        </h1>
        <p style={{ color: 'white', fontSize: '2rem' }}>
        At App Solutions by JRP, we understand that every idea is unique. Our expert team of App developers is dedicated to bringing your vision to life through customized, high-performance applications. Whether you need a sleek web app, a robust mobile solution, or a cross-platform masterpiece, we have the expertise to deliver.
        </p>
        <div className="btn-container">
          <a href="contact.html">
            <button className="primary-btn btn">Get a Quote</button>
          </a>
          <a href="#features">
            <button className="primary-btn btn">Explore More</button>
          </a>
        </div>
      </div>
      <div className="hero-img">
        <img  src={jrpimg} alt="jrp soft tech" />
      </div>

    </section>
  );
};

export default Hero;



