import React from 'react';
import hs from '../img/HS1.png';
import CO from '../img/CO.png';

const Testimonial = () => {
  return (
    <section className="testimonial" id="testimonial">
      <h2>What Our<font color="tomato"> Clients Say</font></h2>
      <p className="section-desc">
        We provide a complete software solutions experience that is not available elsewhere. We have over 100+ happy Clients.
      </p>
      <div className="row">
        <div class="gradient-bg">
        <div className="column">
          <div className="testimonial-image">
            <img src={hs} alt="cust-1 testimonial"/>
          </div>
          <p>
            We are very happy to work with JRP Soft Tech, they are very serious and consistent in their work. JAY and his team of programmers have always been there for us all the time. This is a company I can recommend to anyone to perform any work professionally.
          </p>
          <h3>Hanuman Singh Rajpurohit</h3>
          <h4>Advocate (H.S. Law Firm)</h4>
        </div>
      </div>
      <div class="gradient-bg">
        <div className="column">
          <div className="testimonial-image">
            
            <img src={CO} alt="cust-2 testimonial"/>
          </div>
          <p>
            We thank JRP Soft Tech for the wonderful job in helping us develop our website. Everyone was professional, excellent, and hardworking. Thanks to them, we were able to achieve our goal on time, and we look forward to continue working with them in the future.
          </p>
          <h3>Suresh Purohit</h3>
          <h4>Business Owner</h4>
        </div>
        </div>
      </div>
      
    </section>


  );
};

export default Testimonial;
